.my-vertical-lines-83h2 {
  .verticalLines__first__normal {
    position: absolute;
    width: 1px;
    height: 100%;
    opacity: .2;
    left: 4.23913%;
  }
  .verticalLines__second__normal {
    position: absolute;
    width: 1px;
    height: 100%;
    opacity: .2;
    left: 33.91304%;
  }
  .verticalLines__third__normal {
    position: absolute;
    width: 1px;
    height: 100%;
    opacity: .2;
    left: 66.08696%;
  }
  .verticalLines__fourth__normal {
    position: absolute;
    width: 1px;
    height: 100%;
    opacity: .2;
    left: 95.76087%;
  }
}