@import "assets/font-awesome-pro-6/css/all.css";
@import "assets/font-oswald/main";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.c-pointer {
  cursor: pointer !important;
}

@mixin z-x {
  @for $i from -100 through 100 {
    .z-#{$i} {
      z-index: #{$i};
    }
  }
}

@include z-x;

.jodit-container {
  * {
    font-family: "Oswald";
  }
}
